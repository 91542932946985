// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

import { LitElement, html } from 'lit-element';
import './components/navbar.js';
import './components/timeline.js';

class MainElement extends LitElement {
    createRenderRoot() {
      return this;
    }
  
    render() {
        return html`
        <nav-bar></nav-bar>
        <time-line></time-line>
        `;
    }
}

customElements.define('main-app', MainElement);