import { LitElement, html, css } from "lit-element";

class Navbar extends LitElement {
    
    createRenderRoot() {
      return this;
    }
    
    render() {
        return html`
        <nav class="navbar sticky-top bg-primary navbar-expand-lg" data-bs-theme="dark">
            <div class="container-fluid">
              <a class="navbar-brand" href="#">
                <img src="house.png" style="height:32px" class="me-1 pb-2"/> <span class="fw-bold fs-4">Timeline</span>
              </a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarToggler">
                  <ul class="navbar-nav me-auto mb-2 mb-lg-0">
										<li class="nav-item">
												<a class="nav-link" href="#">Notes</a>
										</li>
										<li class="nav-item">
												<a class="nav-link" href="#">Types</a>
										</li>
									</ul>
              </div>
            </div>
        </nav>
        `
    }
}

customElements.define('nav-bar', Navbar);